//import yourlit from '../../content/assets/logos/YourLit-02.jpg'
import datingGraphic1 from '../../content/assets/other-vector-art/dating-design-04.jpg';
import datingGraphic2 from '../../content/assets/other-vector-art/dating-design-09.jpg';
// import aesthetikLogo from '../../content/assets/logos/aesthetik-logo-1-full.jpg'
import cowboyCatLogo from '../../content/assets/logos/CC-logo-01.png';
import cowboyCatLogo2 from '../../content/assets/logos/CC-logo-full-stacked.jpg';
import aesthetikLogo from '../../content/assets/logos/aesthetik-logo-01.jpg';
import aesthetikLogo2 from '../../content/assets/logos/aesthetik-logo-02.jpg';
import zenlightLogo from '../../content/assets/logos/zen-light-logo.jpg';
import businessIcons from '../../content/assets/other-vector-art/business-icons.jpg';
import deltaFlyer from '../../content/assets/flyers/delta-flyer.png';

// const caption1={<p>Logo design for a conceptual online literary journal</p>}

export const logoTiles = [
    // {
    // img: aesthetikLogo,
    // caption: 'Logo for atmospheric writing application, Aesthetik.',
    // author: 'Liz',
    // cols: 2,
    // },
    {
    img: aesthetikLogo2,
    caption: 'Logo for atmospheric writing application, Aesthetik.',
    author: 'Liz',
    cols: 2,
    },
    {
    img: cowboyCatLogo,
    caption: 'Logo for an indie game studio.',
    author: 'Liz',
    cols: 2,
    },
    {
    img: zenlightLogo,
    caption: 'Zenlight Logo.',
    author: 'Liz',
    cols: 2,
    },
    // {
    // img: cowboyCatLogo2,
    // caption: 'Logo for an indie game studio.',
    // author: 'Liz',
    // cols: 2,
    // },
    {
    img: deltaFlyer,
    caption: 'Flyer for an art contest held by The Delta Journal.',
    author: 'Liz',
    cols: 2,
    },
    {
    img: businessIcons,
    caption: 'Vector icons for business communications.',
    author: 'Liz',
    cols: 2,
    },
    {
     img: datingGraphic1,
     caption: 'graphic depicting an open relationship made for LEGACY magazine.',
     author: 'Liz',
     cols: 2,
    },
    {
     img: datingGraphic2,
     caption: 'graphic depicting an open relationship made for LEGACY magazine.',
     author: 'Liz',
     cols: 2,
    },
  ];

  export default logoTiles;