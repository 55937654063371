import React from 'react'
import { Link } from 'gatsby'

import motifTiles from '../components/motif-tiles'
import blankTiles from '../components/blank-tiles'
//import ModalButton from '../components/modal-button'
import ModalButton from '../components/modal-button-class'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/styles';
import Slide from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import AdjustIcon from '@material-ui/icons/Adjust';
import Modal from '@material-ui/core/Modal';

import Backdrop from '@material-ui/core/Backdrop';
import Collapse from '@material-ui/core/Collapse';


import mutedTheme from '../themes/muted-theme.js';

class TileDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,

    };

  }

  render(){
    var { backgroundColor, fontColor, display, title, subtitle, tiles } = this.props
    //tiles=blankTiles
    return(
      <div>
        <Slide
         in={display}
         direction="up"
         timeout={3000}
         >
          <Box
           className='design-display-box '
           bgcolor={backgroundColor}
           color={fontColor}
          >
            <Box
             className='display-title'
             //bgcolor='primary.light'
             //color='secondary.main'

            >
              <h1
               className='alt-medium-title'
               >
                {title}
              </h1>
              <h1
               className='alt-small-title'
               >
                {subtitle}
              </h1> 
            </Box> 
          <GridList className='grid-list'>
            {tiles.map(tile => (
              
                <GridListTile
                 key={tile.img}
                 className='my-tile'
                 >
                  <GridListTileBar
                   style={{
                    background:'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    zIndex: '16000'
                   }}
                   title={tile.caption}  
                   actionIcon={
                    <ModalButton
                     tiles={tile.img}
                     caption={tile.caption}
                    />

                    }
                  />

                    <img src={tile.img} alt={tile.caption} />
                
                </GridListTile>
           
        ))}
      </GridList> 
             </Box>
        </Slide>



      </div>
    )
  }

}

  export default TileDisplay
