import pattern1 from '../../content/assets/fall-motifs/pattern-design-10.jpg'
//import pattern2 from '../../content/assets/fall-motifs/pattern-design-05.jpg'
import pattern3 from '../../content/assets/fall-motifs/pattern-design-05-2.jpg'
import pattern4 from '../../content/assets/fall-motifs/pattern-design-07.jpg'
import pattern5 from '../../content/assets/fall-motifs/pattern-design-09.jpg'
import pattern6 from '../../content/assets/fall-motifs/pattern-design-03.jpg'
import pattern7 from '../../content/assets/fall-motifs/pattern-design-04.jpg'
import pattern8 from '../../content/assets/fall-motifs/pattern-design-02.jpg'
import pattern9 from '../../content/assets/fall-motifs/pattern design-06.jpg'
export const motifTiles = [
    {
     img: pattern7,
     author: 'author',
     cols: 2,
    },
    {
    img: pattern9,
     author: 'author',
     cols: 2,
    },
    {
     img: pattern8,
     author: 'author',
     cols: 2,
    },
    {
     img: pattern4,
     author: 'author',
     cols: 2,
    },
    {
     img: pattern6,
     author: 'author',
     cols: 2,
    },
    {
     img: pattern1,
     author: 'author',
     cols: 2,
    },
    {
     img: pattern3,
     author: 'author',
     cols: 2,
    },

    {
     img: pattern5,
     author: 'author',
     cols: 2,
    },
  ];

  export default motifTiles