import React from 'react'
import { Link } from 'gatsby'

import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/styles';
// import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DisplayPanel from '../components/display-panel'


import mutedTheme from '../themes/muted-theme.js';


class ProjectExpansionPanel extends React.Component {

  render(){
    var { images, title, backgroundColor, imageBackgroundColor, fontColor, subtitle, details, fontSize, togglePanels, panelState, delay } = this.props

    return(

      <Grow
         in={true}
         // direction="right"
         timeout={1500}
         >
          <Box
           className='my-expansion-panel'
           bgcolor={backgroundColor}
           color={fontColor}
           >   
            <ExpansionPanel
             className='my-expansion-panel'
             square={true}
             // onClick={togglePanels}
             //onChange={togglePanels}
             //expanded={panelState}
             style={{
              boxShadow: 'none',
              background: 'transparent',
              }}
             >
              <Box
               bgcolor={backgroundColor}
               color={fontColor}
                >
                  <ExpansionPanelSummary
                  className='expansion-panel-title'
                   // expandIcon={<ExpandMoreIcon />}
                   aria-controls="panel1a-content"
                   id="panel1a-header"
                    >
                <h1
                 className='alt-medium-title'
                 id='expansion-panel-title'
                 //style={{
                  //border: '2px solid #E7E1E1',
                  //padding: '35px',
                  //width: '400px',
                  //}}
                >
                  {title}
                </h1>
              </ExpansionPanelSummary>
            </Box>
            <Box
             //bgcolor={backgroundColor}
             >
              <ExpansionPanelDetails
               className='my-expansion-details'
               >
        
               {details}
           
              </ExpansionPanelDetails>
            </Box>   
          </ExpansionPanel>
        </Box>
      </Grow>
    )
  }
}


  export default ProjectExpansionPanel;
