import forestScene from '../../content/assets/concept-art/ForestScene.png';
import polySelfPortrait from '../../content/assets/other-vector-art/poly-self-portrait.png';
import polySunflower from '../../content/assets/other-vector-art/sunflowerPoly.png';
import squirrelSketch from '../../content/assets/concept-art/squirrelSketch.png';
import MagicBunnySketch from '../../content/assets/concept-art/MagicBunnySketch.png';
import abra from '../../content/assets/concept-art/abra.jpg';


export const otherArtTiles = [
   {
     img: polySelfPortrait,
     caption: 'a geometric self portrait of Liz',
     author: 'Liz',
     cols: 2,
    },
    {
     img: abra,
     caption: 'a portrait of darkwave musical artist Abra',
     author: 'Liz',
     cols: 2,
    },
    {
     img: polySunflower,
     caption: 'a crystallized sunflower.',
     author: 'Liz', 
     cols: 2,
    },
   {
     img: forestScene,
     caption: 'an 8-bit design of a forest for a video game',
     author: 'Liz',
     cols: 2,
    },

  ];

  export default otherArtTiles