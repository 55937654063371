import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DisplayPanel from "../components/display-panel"
import ProjectExpansionPanel from "../components/project-expansion-panel"
import motifTiles from "../components/motif-tiles"
import logoTiles from "../components/logo-tiles"
import otherArtTiles from "../components/other-art-tiles"
// import blankTiles from '../components/blank-tiles'
// import sketchTiles from '../components/sketch-tiles'
import TileDisplay from "../components/tile-display"
import "../styles/site.css"

import Box from "@material-ui/core/Box"
//import { makeStyles } from '@material-ui/core/styles';
// import Grow from '@material-ui/core/Grow';
// import Divider from '@material-ui/core/Divider';
// import { shadows } from '@material-ui/system';
import aesthetikLogo2 from "../../content/assets/logos/aesthetik-logo-02.png"

//var me = require('../../content/assets/tinyme.png')
var moonman = require("../../content/assets/moon-shooter-guy.png")
var bkgrnd1 = require("../../content/assets/stars.jpg")
var delt60 = require("../../content/assets/delta60-screenshot-1.png")
var fox = require("../../content/assets/fox-sprite.png")
var plants = require("../../content/assets/plants-4.jpg")
var floral = require("../../content/assets/floral-1.jpg")
var succulent = require("../../content/assets/succulent-2.png")
var split = require("../../content/assets/split.png")

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
//   panel1: {
//     background: 'primary.dark',
//     width: '100vw',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   panel2: {
//     background: 'primary.main',
//     width: '100vw',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',

//   },
//   panel3: {
//     background: 'primary.main',
//     width: '100vw',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',

//   },
// }));

class Projects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: false,
      tileDisplay: false,
    }
    this.handleDisplay = this.handleDisplay.bind(this)
    this.handleTileDisplay = this.handleTileDisplay.bind(this)
  }

  handleDisplay() {
    if (this.state.display === false) {
      this.setState({ display: true })
    } else {
      this.setState({ display: false })
    }
  }

  handleTileDisplay() {
    if (this.state.tileDisplay === false) {
      this.setState({ tileDisplay: true })
    } else {
      this.setState({ tileDisplay: false })
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    //const classes = useStyles

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="LVN Projects"
          description="Take a look at some of Liz Nelson's creative projects. These include some indie games, web projects, art, and more."
        />
        <Box
          display="flex"
          className="light-page"
          bgcolor="primary.dark"
          style={{
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            //className='block-header-gradient'
            id="project-background"
            style={{
              background: `url(${floral})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              // backgroundPosition: 'left'
            }}
          >
            <Box
              className="block-header-gradient"
              style={{
                //alignSelf: 'flex-start',
                minHeight: "100vh",
                flexDirection: "column",
                //border: '2px solid red'
              }}
            >
              {/* <Box
             className='block-header-gradient-overlay'
             //style={{paddingTop: '0px'}}
            >
            </Box> */}
              <ProjectExpansionPanel
                title="creative coding"
                //backgroundColor='primary.light'
                fontColor="primary.light"
                details={
                  <Box>
                    <DisplayPanel
                      image={fox}
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                      displayText={
                        <p>
                          In progress since 2020. A 2D top-down, open world,
                          action-adventure fantasy RPG with stealth elements.
                          The game is about a brave young girl named Puff who is
                          tasked with uniting 10 magical beings chosen to save
                          an ancient forest and the life it hosts from an evil
                          and greedy king who places property and conquest above
                          all else. This is a tale of great bravery in the face
                          of powerful evil and injustice, the transformative
                          power of true friendship, and respecting life in all
                          its forms.
                        </p>
                      }
                      title="FoxSpirit"
                      display={true}
                      imageAlign="left"
                      delay={2000}
                      githubHref="https://github.com/vidella-lee/guardians-of-the-forest"
                    />
                    <DisplayPanel
                      image={aesthetikLogo2}
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                      imageAlign="right"
                      displayText={
                        <p>
                          A minimalist, atmospheric app designed to inspire
                          writers to creativity and productivity. Create a
                          themed writing environment with custom background,
                          optional typing sound effect, and ambient background
                          noise. Writer's block begone!
                          <br />
                        </p>
                      }
                      title="Aesthetik"
                      display={true}
                      delay={1000}
                      githubHref="https://github.com/PeachyPuff/the-program-pals"
                    />
                    <DisplayPanel
                      image={moonman}
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                      displayText={
                        <p>
                          Prototype. A mini-game built in p5.js. Help Moon Man
                          kill all the alien bugs before they kill him. Art
                          assets made using Adobe Illustrator CC.
                        </p>
                      }
                      title="Moon Shooter"
                      subtitle="2017"
                      display={true}
                      delay={3000}
                      githubHref="https://github.com/vidella-lee/Moon-Shooter"
                    />
                  </Box>
                }
              />
              <ProjectExpansionPanel
                title="art & graphic design"
                //backgroundColor='primary.light'
                fontColor="primary.light"
                details={
                  <Box>
                    <TileDisplay
                      tiles={logoTiles}
                      display={true}
                      title="Logos & Graphic Design"
                      //subtitle='Fall Motifs'
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                    />
                    <TileDisplay
                      tiles={otherArtTiles}
                      display={true}
                      title="Poly, Pixel, Portraits, & Concept Art"
                      //subtitle=' & Sketches'
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                    />
                    <TileDisplay
                      tiles={motifTiles}
                      display={true}
                      title="Vector Art"
                      subtitle="Fall Motifs"
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                    />
                  </Box>
                }
              />
              <ProjectExpansionPanel
                title="web design"
                //backgroundColor='primary.light'
                fontColor="primary.light"
                details={
                  <Box>
                    <DisplayPanel
                      image={delt60}
                      displayText={
                        <p>
                          Designed a website to showcase LSU's{" "}
                          <a
                            href="https://thedelta60.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Delta Literary Journal's 60th volume{" "}
                          </a>{" "}
                          and the amazing writers selected to represent the
                          university's literary talent.
                          <br />
                        </p>
                      }
                      title={
                        <a
                          href="https://thedelta60.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Delta 60
                        </a>
                      }
                      display={true}
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                    />

                    <DisplayPanel
                      image={split}
                      displayText={
                        <p>
                          A generic business website template.
                          <br />
                        </p>
                      }
                      title={
                        <a
                          href="https://split-website-template.netlify.app/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Split Website Template
                        </a>
                      }
                      display={true}
                      backgroundColor="primary.light"
                      fontColor="primary.main"
                      githubHref="https://github.com/vidella-lee/slanted-website-template"
                    />
                  </Box>
                }
              />
            </Box>
          </Box>
        </Box>
      </Layout>
    )
  }
}

export default Projects

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
