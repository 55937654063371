import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
// import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
// import ButtonBase from '@material-ui/core/ButtonBase';
import AdjustIcon from '@material-ui/icons/Adjust';
import Box from '@material-ui/core/Box';


class TileDisplay extends React.Component {
   
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,

    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
   handleOpen(){
      this.setState({setOpen: true})

    };

    handleClose(){
      this.setState({setOpen: false})
    };

  render(){
    var { tiles, caption } = this.props


  return (
    <div>
       <IconButton
        onClick={this.handleOpen}
        >
         <AdjustIcon
           color='primary'
          />
        </IconButton>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='my-modal'
        open={this.state.setOpen}
        onClose={this.handleClose}

        disableScrollLock={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          zIndex: '9999999',
        }}
      > 
       
       
            <Box
             className='modal-image'
              >
              <img
               src={tiles}
               alt={caption}
               className='modal-image-2'
               />
                
                 <Box
                  className='caption-box'
                  color='primary.light'
                  >
                   <h4 style={{margin: '0px'}}> {caption}</h4>
               </Box>
              </Box>
 
        
             
      </Modal>
    </div>
  );
}
}

export default TileDisplay