import React from 'react'

import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { fab } from '@fortawesome/free-brands-svg-icons'


class DisplayPanel extends React.Component {
  render() {
    var { image, displayText, title, display, backgroundColor, imageBackgroundColor, imageAlign, fontColor, subtitle, delay, githubHref } = this.props;
    // const socialMediaIcons = [
    // {
    //   url: 'https://github.com/vidella-lee',
    //   className: 'fa-github-square',
    // },
    // {
    //   url: 'https://twitter.com/vidella_lee',
    //   className: 'fa-twitter',
    // },
    // ];
    imageAlign = 'left';
    if (imageAlign === 'left' || null) {
      return (
        <div>
          <Grow
            in={display}
            //direction="down"
            timeout={1500 + delay}
          >
            <Box
              className='display-box'
              bgcolor={backgroundColor}
              color={fontColor}
            >
              <Box
                className='display-image'
                style={{
                  backgroundImage: 'url(' + image + ')',
                }}
                bgcolor={imageBackgroundColor}
              >
              </Box>

              <Box
                className='display-title'
              >
                <h1
                  className='alt-medium-title'
                >
                  {title}
                </h1>
                <h4
                  className='small-title'
                >
                  {subtitle}
                </h4>
              </Box>
              <Box
                className='display-text'
              >
                {displayText}
              </Box>
              {githubHref ?
                <Box
                  color='primary.dark'>
                  <a
                    href={githubHref}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'github']}
                      className='icon-link'
                    />

                  </a>
                </Box> :
                null}
              <p>
                <hr class='solid-line' />
              </p>
            </Box>
          </Grow>

        </div>
      )
    }
    else {
      return (
        <div>
          <Grow
            in={display}
            direction="up"
            timeout={3000}
          >
            <Box
              className='display-box'
              bgcolor={backgroundColor}
              color='primary.main'
            >
              <Divider
                //light={true}
                variant='middle'
                orientation='vertical'
              />
              <Box
                className='display-title'
              >
                <h1
                  className='medium-title'
                >
                  {title}
                </h1>
                <h4
                  className='small-title'
                >
                  {subtitle}
                </h4>
              </Box>
              <Box
                className='display-text'
              >


                {displayText}

              </Box>
              <Box
                className='display-image'
                style={{
                  backgroundImage: 'url(' + image + ')',
                }}
                bgcolor={imageBackgroundColor}
              >
              </Box>
            </Box>
            <div
              className='display-image'
              style={{
                backgroundImage: 'url(' + image + ')',
              }}
            >
            </div>
          </Grow>

        </div>
      )
    }
  }
}

export default DisplayPanel
