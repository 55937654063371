import blank from '../../content/assets/blank-tile.jpg'

export const blankTiles = [
   {
     img: blank,
     title: 'a placeholder for an image ',
     author: 'author',
     cols: 2,
    },
    {
     img: blank,
     title: 'a placeholder for an image ',
     author: 'author',
     cols: 2,
    },
    {
     img: blank,
     title: 'a placeholder for an image ',
     author: 'author',
     cols: 2,
    },
     {
     img: blank,
     title: 'a placeholder for an image ',
     author: 'author',
     cols: 2,
    },
    {
     img: blank,
     title: 'a placeholder for an image',
     author: 'author',
     cols: 2,
    },
  ];

  export default blankTiles